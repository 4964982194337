


































import User from "@/models/User"
import {
  defineComponent,
  PropType,
  computed,
  reactive,
} from "@vue/composition-api"
import Loading from "@/components/Loading.vue"
import { i18nContainer } from "@/containers/I18nContainer"

interface State {
  errorMsg: string
}

export default defineComponent({
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    requiredMe: {
      type: Boolean,
      default: false,
    },
    autherId: String,
    value: Array as PropType<User[]>,
    users: {
      type: Array as PropType<User[]>,
      required: true,
    },
    maxWidth: [String, Number],
    maxHeight: [String, Number],
    disabled: Boolean,
    readonly: Boolean,
  },
  components: {
    Loading,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      errorMsg: "",
    })
    const { formatMessage } = i18nContainer.useContainer()

    const selected = computed({
      get: () => {
        return props.value ?? []
      },
      set: (changedUsers: Array<User>) => {
        emit("input", changedUsers)
        return changedUsers
      },
    })
    const rules = computed(() => {
      if (props.requiredMe) {
        return [
          () => {
            if (!selected.value.some(u => u.userId === props.autherId)) {
              state.errorMsg = formatMessage({
                id: "UsersList.requiredMe",
                defaultMessage: "自身の選択は必須です。",
              })
              return state.errorMsg
            }
            state.errorMsg = ""
            return true
          },
        ]
      } else if (props.required) {
        return [
          (value: User[]) => {
            if (value && value.length === 0) {
              state.errorMsg = formatMessage({
                id: "UsersList.required",
                defaultMessage: "入力は必須です。",
              })
              return state.errorMsg
            }
            state.errorMsg = ""
            return true
          },
        ]
      } else {
        state.errorMsg = ""
        return []
      }
    })

    return { state, rules, selected }
  },
})
